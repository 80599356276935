import { SagaIterator } from 'redux-saga';
import { all, AllEffect } from 'redux-saga/effects';

import * as account from './modules/account';
import * as dialog from './modules/dialog';
import * as payment from './modules/payment';
import * as character from './modules/character';
import * as gift from './modules/gift';

export default function* root(): TRootSaga {
  yield all([
    account.sagas(),
    dialog.sagas(),
    payment.sagas(),
    character.sagas(),
    gift.sagas(),
  ]);
}

export type TRootSaga = Generator<AllEffect<SagaIterator>, void, unknown>;
