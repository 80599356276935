import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';
import moduleSagas from './sagas';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setCheckoutSession(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['checkoutSession']>
    ) {
      state.data.checkoutSession = action.payload;
    },

    setSubscription(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['subscription']>
    ) {
      state.data.subscription = action.payload;
    },

    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const sagas = moduleSagas;
export const state = moduleState;
