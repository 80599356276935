import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';

import * as botServiceTypes from 'src/services/AiVersations/bot/types';
import { getSession } from 'src/services/AiVersations/session';

export enum SESSION_STEPS {
  'personaType' = 'personaType',
}
export enum SESSION_STEPS_GENERATED {
  'personaType' = 'personaType',
  'mode' = 'mode',
  'gender' = 'gender',
  'nickName' = 'nickName',
  'faceTraits' = 'faceTraits',
  'image_processing' = 'image_processing',
  'image_select' = 'image_select',
  'personaTraits' = 'personaTraits',
  'customPersonaFacts' = 'customPersonaFacts',
  'complited' = 'complited',
}
export enum SESSION_STEPS_UPLOAD {
  'personaType' = 'personaType',
  'gender' = 'gender',
  'imageUpload' = 'imageUpload',
  'nickName' = 'nickName',
  'personaTraits' = 'personaTraits',
  'customPersonaFacts' = 'customPersonaFacts',
  'complited' = 'complited',
}
export interface ISessionResults {
  mode: botServiceTypes.IPostBotSessionModeParams['mode'];
  gender: botServiceTypes.IPostBotSessionGenderParams['gender'];
  nickname: botServiceTypes.IPostBotSessionNickNameParams['nickname'];
  face: botServiceTypes.IPostBotSessionFaceTraitsParams['face_traits'];
  persona: botServiceTypes.IPostBotSessionPersonaTraitsParams['persona_traits'];
  image_id?: botServiceTypes.IPostBotSessionImageParams['image_id'];
  persona_facts?: botServiceTypes.IPostBotSessionPersonaFactsParams['persona_facts'];
  user_image?: botServiceTypes.IPostBotSessionUserImageParams['image']
}
export interface IBotCreateSession  {

  step: `${SESSION_STEPS}` | `${SESSION_STEPS_GENERATED}` | `${SESSION_STEPS_UPLOAD}`;
  personaType: string;
  create: botServiceTypes.IGetBotSessionCreateData;
  mode: botServiceTypes.IPostBotSessionModeData;
  gender: botServiceTypes.IPostBotSessionGenderData;
  nickName: botServiceTypes.IPostBotSessionNickNameData;
  faceTraits: botServiceTypes.IPostBotSessionFaceTraitsData;
  image?: botServiceTypes.IPostBotSessionImageData;
  personaTraits?: botServiceTypes.IPostBotSessionPersonaTraitsData;
  userImage?: botServiceTypes.IPostBotSessionUserImageData;
  imageProcessingLimit?: botServiceTypes.IPostBotSessionFaceTraitsError;
  personaFactsError?: botServiceTypes.IPostBotSessionPersonaFactsError;
  results: ISessionResults
  commit?: botServiceTypes.IPostBotSessionUserImageCommitData
}
export interface IStateData {
  shareBotTooltipClosed?: boolean;
  traits?: botServiceTypes.ITraitsListItem[];
  sliders?: botServiceTypes.ISlider;
  botCreated?: botServiceTypes.IBotCreated;
  createSession: IBotCreateSession;
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  const session = getSession();

  return {
    shareBotTooltipClosed: session.shareBotTooltipClosed,
    traits: undefined,
    sliders: undefined,
    botCreated: undefined,
    createSession: {
      step: SESSION_STEPS.personaType,
      personaType: '',
      create: {
        create_bot_session_id: '',
        mode: {
          image_size_web:'',
          title: '',
          options:[]
        },
        gender: {
          image_size_web:'',
          title: '',
          options:[]
        },
        suggested_persona_facts: []
      },
      mode: {
        gender: {
          image_size_web:'',
          title: '',
          options:[]
        },
      },
      gender: {
        create_bot_session_id:'',
        name_chooses: [],
      },
      nickName: {
        create_bot_session_id: '',
        face_traits: {},
        persona_traits: {},
      },
      faceTraits: [],
      image: undefined,
      personaTraits: undefined,
      results:{
        mode: 'reaclistic',
        gender: '',
        nickname: '',
        image_id: undefined,
        face: {},
        persona: {},
        persona_facts: []
      }
    }
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
