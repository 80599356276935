import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { Icon, IIconProps } from 'src/components/Icon';
import { ICreateChatbotPayload } from 'src/services/analytics/events';
import { useMediaPhone } from 'src/hooks/useMediaQuery';

import styles from './NavigationMobile.module.scss';

const sx = classNames.bind(styles);

interface INavItemProps extends NavLinkProps {
  icon?: IIconProps['name'];
  iconProps?: IIconProps;
  label?: string;
  className?: string;
}

export const NavItem = (props: INavItemProps) => {
  const { icon, iconProps, to, label, className, onClick } = props;

  return (
    <NavLink
      to={to}
      className={({ isActive }) => sx('item', className, { active: isActive })}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
    >
      {icon && (
        <div className={sx('item-icon')}>
          <Icon {...iconProps} name={icon} />
        </div>
      )}
      {label && <div className={sx('item-label')}>{label}</div>}
    </NavLink>
  );
};

export const NavigationMobile = () => {
  const isPhone = useMediaPhone();

  if (!isPhone) return null;

  return (
    <div className={sx('container')}>
      <div className={sx('list')}>
        <NavItem icon="Home" to="/" label="Explore" />
        {/* <NavItem
          icon="ProfileAdd"
          to="/characters/create?source=navbar"
          label="Create"
          className="character-create"
        /> */}
        <NavItem icon="Messages" to="/chats" label="Chats" />
      </div>
    </div>
  );
};
