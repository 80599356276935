import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';
import * as sessionService from 'src/services/AiVersations/session';
import * as userService from 'src/services/AiVersations/user';
import * as paymentServiceTypes from 'src/services/AiVersations/payment/types';

export interface IAccountProfile {
  displayName?: string;
  email?: string;
  photoUrl?: string;
  provider?: string;
}
export interface IStateData {
  clientToken?: string;
  subscriptionEmail?: string;
  emailVerification?: {
    email: string;
    required: boolean;
  };
  idToken?: string;
  confirmAdult?: boolean;
  profile?: IAccountProfile;
  user?: userService.IGetUserData;
  wallet?: paymentServiceTypes.IGetWalletData;
  subscription?: paymentServiceTypes.IGetSubscriptionData;
  analyticsUTM?: sessionService.ISession['analyticsUTM'];
  superOffer?:sessionService.ISession['superOffer']
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  const session = sessionService.getSession();

  return {
    clientToken: session.clientToken,
    idToken: session.firebaseIdToken,
    confirmAdult: session.confirmAdult,
    profile: {
      displayName: session?.displayName,
      photoUrl: session.photoUrl,
      email: session.email,
    },
    wallet: undefined,
    user: undefined,
    subscription: session.subscription,
    analyticsUTM: session.analyticsUTM,
    superOffer: session.superOffer,
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
