import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';
import moduleSagas from './sagas';
import * as moduleSelectors from './selectors';
import './service';
export * from './state';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    emailVerification(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['emailVerification']>
    ) {
      state.data.emailVerification = action.payload;
    },
    setSubscriptionEmail(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['subscriptionEmail']>
    ) {
      state.data.subscriptionEmail = action.payload;
    },
    setUser(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['user']>
    ) {
      state.data.user = action.payload;
    },
    setIdToken(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['idToken']>
    ) {
      state.data.idToken = action.payload;
    },
    setClientToken(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['clientToken']>
    ) {
      state.data.clientToken = action.payload;
    },
    setConfirmAdult(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['confirmAdult']>
    ) {
      state.data.confirmAdult = action.payload;
    },
    setProfile(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['profile']>
    ) {
      state.data.profile = {
        ...state.data.profile,
        ...action.payload,
      };
    },
    setSubscription(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['subscription']>
    ) {
      state.data.subscription = action.payload;
    },
    setWallet(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['wallet']>
    ) {
      state.data.wallet = action.payload;
    },
    setWalletBalance(state: moduleState.IState, action: PayloadAction<number>) {
      state.data.wallet = {
        ...state.data.wallet,
        balance: action.payload,
      };
    },
    setAnalyticsUTM(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['analyticsUTM']>
    ) {
      state.data.analyticsUTM = action.payload;
    },
    setSuperOffer(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['superOffer']>
    ) {
      state.data.superOffer = {
        ...state.data.superOffer,
        ...action.payload,
      };
    },
    setCustomBotCountUpdate(
      state: moduleState.IState,
      action: PayloadAction<number>
    ) {
      if (state.data.user){
        state.data.user.new_custom_bot_count = action.payload
      }
    },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const selectors = moduleSelectors.selectors
export const sagas = moduleSagas;
export const state = moduleState;
