import {
  call,
  all,
  put,
  takeLeading,
  delay,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';
import { gtag, googleTagManagerConsentGranted } from 'src/services/analytics/providers/GoogleTagManager';
import { FacebookPixel, facebookPixelInitialize } from 'src/services/analytics/providers/FacebookPixel';
import { posthogInitialize } from 'src/services/analytics/providers/Posthog';
import { firebaseInitialize } from 'src/services/AiVersations/firebase';
import { IResponse } from 'src/services/AiVersations/http';
import * as firebaseService from 'src/services/AiVersations/firebase';
import * as userService from 'src/services/AiVersations/user';
import * as sessionService from 'src/services/AiVersations/session';
import * as socketService from 'src/services/socket';
import * as paymentService from 'src/services/AiVersations/payment';
import { ISuperOffer } from 'src/services/AiVersations/session';
import { analyticsEvents } from 'src/services/analytics/events';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { posthog } from 'src/services/analytics/providers/Posthog';
import { ESubscriptionReason } from 'src/services/analytics/events';
// import { actions as dialogActions } from 'src/redux/modules/dialog/actions';
import { actions } from './actions';
import { selectors } from './selectors';
import { setters } from './index';
export default function* sagas() {
  yield all([
    takeLeading(
      actions.appReady.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.appReady.pending>) {
        try {
          yield call(userService.postAppReady);
          yield put(actions.appReady.fulfilled());
        } catch (error) {
          yield put(actions.appReady.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.sessionSetup.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionSetup.pending>) {
        try {
          const session = sessionService.getSession();

          yield put(setters.setClear())

          if (!session.clientToken) {
            const user: IResponse<userService.IPostUserData> = yield call(
              userService.postUser,
              { query: session.analyticsUTM }
            );

            yield put(setters.setUser(user.data));

            sessionService.setSession({ clientToken: user.data.uuid });

            socketService.connectNamespaces(user.data.uuid);

            yield put(setters.setClientToken(user.data.uuid));
          } else {
            try {
              facebookPixelInitialize()
              firebaseInitialize()
              posthogInitialize()

              if (session.firebaseIdToken) {
                const idToken: string = yield call(
                  firebaseService.getIdToken,
                  true
                );

                sessionService.setSession({
                  firebaseIdToken: idToken,
                });

                yield put(actions.sessionRefresh.pending());
              }

              const user: IResponse<userService.IGetUserData> = yield call(
                userService.getUser
              );

              yield put(setters.setUser(user.data));

              if (session.firebaseIdToken) {

                yield put(
                  actions.getSubscription.pending({
                    subscription: session.subscription,
                  })
                );

                yield put(actions.getWallet.pending());

                sessionService.setSession({
                  authClientToken: user.data.uuid,
                });

              }

              socketService.connectNamespaces(user.data.uuid);

              yield put(setters.setClientToken(user.data.uuid));
            } catch {
              if (session.authClientToken) {
                yield put(actions.logout.pending());
              } else {
                sessionService.clearSession()
              }

            }

            yield put(actions.analyticsUserAuth.pending())
            yield put(actions.analyticUserProperties.pending());
          }

          yield put(actions.sessionSetup.fulfilled());
        } catch (error) {
          yield put(actions.sessionSetup.rejected(error as Error));
        }
      }
    ),
    takeLatest(
      actions.getClientEmail.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getClientEmail.pending>) {
        try {
          yield put(actions.getClientEmail.fulfilled());
          const email: IResponse<{email:string}> = yield call(userService.getEmail);
          yield put(setters.setSubscriptionEmail(email.data.email))
        } catch (error) {
          yield put(actions.getClientEmail.rejected(error as Error));
        }
      }
    ),

    takeLatest(
      actions.sessionRefresh.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sessionRefresh.pending>) {
        try {
          const session = sessionService.getSession();

          if (!session.firebaseIdToken) {
            yield put(actions.sessionRefresh.fulfilled());
            return;
          }

          const result: firebaseService.IdTokenResult = yield call(
            firebaseService.getIdTokenResult,
            true
          );
          const currentTime = new Date().getTime();

          if (!result?.expirationTime) {
            yield put(actions.sessionRefresh.fulfilled());
            return;
          } else {
            const idToken: string = yield call(
              firebaseService.getIdToken,
              true
            );

            sessionService.setSession({
              firebaseIdToken: idToken,
            });
          }

          const expirationTime = new Date(result.expirationTime).getTime();
          const minute = 1000 * 60;
          const timerTime = expirationTime - currentTime - minute;

          if (timerTime) {
            yield delay(timerTime);
            yield put(actions.sessionRefresh.pending());
          }

          yield put(actions.sessionRefresh.fulfilled());
        } catch (error) {
          yield put(actions.sessionRefresh.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.confirmAdult.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.confirmAdult.pending>) {
        try {
          const { isConfirm, isCoockieConfirm } = action.payload;
          const session = sessionService.getSession()
          yield call(
            userService.pathUser,
            {
              is_adult_confirm: isConfirm,
              query: session.analyticsUTM
            }
          );

          sessionService.setSession({ confirmAdult: isConfirm });

          yield put(setters.setConfirmAdult(isConfirm));

          googleTagManagerConsentGranted()
          facebookPixelInitialize()
          firebaseInitialize()
          posthogInitialize()

          analyticsEvents.consentGranted({
            cookies_consent: isCoockieConfirm,
            ai_18_consent: isConfirm,
          });

          yield put(actions.confirmAdult.fulfilled());
        } catch (error) {
          yield put(actions.confirmAdult.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.auth.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.auth.pending>) {
        try {
          const { provider } = action.payload;

          yield call(firebaseService.auth, provider);
          yield put(actions.auth.fulfilled());
        } catch (error) {
          console.error(error);
          yield put(actions.auth.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.authStateChanged.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.authStateChanged.pending>
      ) {
        try {
          const currentUser = firebaseService?.app.auth?.currentUser;
          const userProvider = firebaseService.getUserProvider()

          if (currentUser) {
            if (currentUser.emailVerified === false) {
              yield put(setters.emailVerification({
                email: currentUser.email || '',
                required: true,
              }))
              yield put(actions.authStateChanged.fulfilled());

              return;
            } else {
              setters.emailVerification(undefined)
            }
            const session = sessionService.getSession();


            const idToken: string = yield call(
              firebaseService.getIdToken,
              false
            );
            const { photoURL, displayName, email } = currentUser;

            sessionService.setSession({
              displayName: displayName || undefined,
              email: email || undefined,
              firebaseIdToken: idToken,
              photoUrl: photoURL || undefined,
            });

            yield put(setters.setIdToken(idToken || ''));

            yield put(
              setters.setProfile({
                provider: userProvider,
                displayName: displayName || '',
                email: email || '',
                photoUrl: photoURL || '',
              })
            );

            yield put(actions.analyticUserProperties.pending());

            if (!session.authClientToken) {
              yield put(actions.sessionSetup.pending());
            }
          }

          yield put(actions.authStateChanged.fulfilled());
        } catch (error) {
          yield put(actions.authStateChanged.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.logout.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.logout.pending>) {
        try {
          yield call(firebaseService.signOut);
          yield put(setters.setIdToken(''));
          yield put(
            setters.setProfile({
              displayName: '',
              email: '',
              photoUrl: '',
            })
          );
          yield put(setters.setSubscription(undefined));
          yield put(setters.setWallet(undefined));

          const session = sessionService.getSession();
          const isClientIdEqualUserId =
            session.clientToken === session.authClientToken;

          sessionService.clearSession({
            clientToken: isClientIdEqualUserId
              ? undefined
              : session.clientToken,
          });
          yield put(actions.analyticUserProperties.pending());
          yield put(actions.logout.fulfilled());

          yield put(actions.sessionSetup.pending());
        } catch (error) {
          yield put(actions.logout.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.signIn.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signIn.pending>) {
        try {
          yield call(firebaseService.signInEmailPassword, action.payload);
          yield put(actions.signIn.fulfilled());
        } catch (error) {
          yield put(actions.signIn.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.signUp.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signUp.pending>) {
        try {
          yield call(firebaseService.signUpEmailPassword, action.payload);
          yield put(actions.sendEmailVerification.pending())
          yield put(actions.signUp.fulfilled());
        } catch (error) {
          yield put(actions.signUp.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sendEmailVerification.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signUp.pending>) {
        try {
          const currentUser = firebaseService?.app.auth?.currentUser;
          const url = `${location.origin}/?modal=email-verification&status=success`
          yield call(firebaseService.sendEmailVerification, url);


          yield put(actions.sendEmailVerification.fulfilled());
        } catch (error) {
          yield put(actions.sendEmailVerification.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.signInEmailLink.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.signInEmailLink.pending>) {
        try {
          yield call(firebaseService.signInEmailLink);
          yield put(actions.signInEmailLink.fulfilled());

        } catch (error) {
          yield put(actions.signInEmailLink.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.recoveryPassword.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.recoveryPassword.pending>) {
        try {
          yield call(firebaseService.resetPasswordLink, action.payload);
          yield put(actions.recoveryPassword.fulfilled());
        } catch (error) {
          console.debug(error)
          yield put(actions.recoveryPassword.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.sendChangePasswordLink.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.sendChangePasswordLink.pending>) {
        try {
          yield call(firebaseService.sendChangePasswordLink);
          yield put(actions.sendChangePasswordLink.fulfilled());
        } catch (error) {
          console.debug(error)
          yield put(actions.sendChangePasswordLink.rejected(error as Error));
        }
      }
    ),
    // takeLeading(
    //   actions.changePassword.pending.toString(),
    //   function* (action: ActionPalyoad<typeof actions.changePassword.pending>) {
    //     try {
    //       yield call(firebaseService.changePassword, action.payload);
    //       yield put(actions.changePassword.fulfilled());
    //     } catch (error) {
    //       console.debug(error)
    //       yield put(actions.changePassword.rejected(error as Error));
    //     }
    //   }
    // ),
    takeLeading(
      actions.deleteAccount.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.deleteAccount.pending>) {
        try {
          const session = sessionService.getSession();
          const isClientIdEqualUserId =
            session.clientToken === session.authClientToken;

          yield call(firebaseService.deleteAccount, action.payload);
          yield call(userService.deleteUser);
          yield put(setters.setIdToken(''));
          yield put(
            setters.setProfile({
              displayName: '',
              email: '',
              photoUrl: '',
            })
          );
          yield put(setters.setSubscription(undefined));
          yield put(setters.setWallet(undefined));

          sessionService.clearSession({
            subscription: undefined,
            clientToken: isClientIdEqualUserId
              ? undefined
              : session.clientToken,
          });

          yield put(actions.deleteAccount.fulfilled());
          yield put(actions.analyticUserProperties.pending());
          yield put(actions.sessionSetup.pending());

        } catch (error) {
          yield put(actions.deleteAccount.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.updateEmail.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.updateEmail.pending>) {
        try {
          const { email } = action.payload;

          yield call(firebaseService.updateEmail, email);
          yield put(actions.analyticUserProperties.pending());
          yield put(actions.updateEmail.fulfilled());
        } catch (error) {
          yield put(actions.updateEmail.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.updateProfile.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.updateProfile.pending>) {
        try {
          const { displayName, photoUrl } = action.payload;

          yield call(firebaseService.updateProfile, {
            displayName,
            photoURL: photoUrl,
          });

          if (displayName) {
            yield put(setters.setProfile({ displayName }));
            sessionService.setSession({ displayName });
          }

          if (photoUrl) {
            yield put(setters.setProfile({ photoUrl }));
            sessionService.setSession({ photoUrl });
          }

          yield put(actions.updateProfile.fulfilled());
        } catch (error) {
          yield put(actions.updateProfile.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.uploadAvatar.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.uploadAvatar.pending>) {
        try {
          const { file } = action.payload;

          const result: { photoURL: string } = yield call(
            firebaseService.uploadAvatar,
            { file }
          );
          yield put(
            actions.updateProfile.pending({ photoUrl: result.photoURL })
          );
          yield put(actions.uploadAvatar.fulfilled());
        } catch (error) {
          yield put(actions.uploadAvatar.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.getSubscription.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getSubscription.pending>
      ) {
        try {
          const { payload } = action;

          if (payload?.subscription) {
            yield put(setters.setSubscription(payload.subscription));
            const session = sessionService.getSession()
            sessionService.setSession({
              subscription: payload.subscription,
              isFirstSubscription: !session.subscription
            });
          }

          const subcription: IResponse<paymentService.IGetSubscriptionData> = yield call(
            paymentService.getSubscription
          );

          yield put(setters.setSubscription(subcription.data));
          yield put(actions.analyticUserProperties.pending());
          yield put(actions.getSubscription.fulfilled());
        } catch {
          yield put(actions.getSubscription.fulfilled());
          yield put(setters.setSubscription(undefined));
          sessionService.setSession({
            subscription: undefined
          })
        }
      }
    ),
    takeLeading(
      actions.getWallet.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getWallet.pending>) {
        try {
          const wallet: IResponse<paymentService.IGetWalletData> = yield call(
            paymentService.getWallet
          );
          yield put(setters.setWallet(wallet.data));

          yield put(actions.getWallet.fulfilled());
        } catch (error) {
          yield put(actions.getWallet.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.changeWalletBalance.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.changeWalletBalance.pending>
      ) {
        try {
          yield put(setters.setWalletBalance(action.payload.balance));

          yield put(actions.changeWalletBalance.fulfilled());
        } catch (error) {
          yield put(actions.changeWalletBalance.rejected(error as Error));
        }
      }
    ),
    takeLatest(
      actions.analyticUserProperties.pending.toString(),
      function* () {
        try {
          const user: userService.IUser | undefined = yield select(selectors.user)
          const isSubscribled: boolean = yield select(selectors.isSubscribled)
          const {
            email,
            confirmAdult,
            analyticsUTM
          } = sessionService.getSession();
          const provider = firebaseService.getUserProvider() as string
          const user_id = user?.uuid;
          const userProperies = {
            provider,
            email: email || '',
            cookies_consent_status: Boolean(confirmAdult),
            subscription_state: isSubscribled,
            ...analyticsUTM,
            'context_generation_photos_01/24': user?.context_generation,
            'AB.blurred.sexting.messages.16.02.2024': user?.ab_blurred_sexting_messages_16_02_2024,

            'AB.hearts.paywall.29_11_23': user?.hearts_paywall_group,
            'AB.hearts.paywall.1.15.0': user?.ab_hearts_paywall_1_15_0_group,

            'AB.subscription.paywall.29_11_23': user?.subscription_paywall_group,
            'AB.subscription.paywall.1.15.0': user?.ab_subscription_paywall_1_15_0_group,
            'AB.subscription.paywall.02.2024': user?.ab_subscription_paywall_02_2024_group,
            'user_image_custom_bot': user?.user_image_custom_bot,

          }
          posthog.identify(
            user_id,
            userProperies
          );
          gtag('set', {
            'user_properties': {
              user_id,
              ...userProperies
            }
          });
          yield put(setters.setAnalyticsUTM(analyticsUTM))
          yield put(actions.analyticUserProperties.fulfilled());
        } catch (error) {
          yield put(actions.analyticUserProperties.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.analyticsUserAuth.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.analyticsUserAuth.pending>
      ) {
        try {
          const isFirstSession = sessionService.isFirstSession();
          const { firebaseIdToken, authClientToken, clientToken } =
            sessionService.getSession();
          const isNewUser =
            authClientToken && clientToken && authClientToken === clientToken;
          const isRegistration = firebaseIdToken && isNewUser;
          const isLogin = firebaseIdToken && !isNewUser;

          if (isRegistration) {
            const provider = firebaseService.getUserProvider() as string
            FacebookPixel.track('CompleteRegistration');

            analyticsEvents.userRegistered({
              registration_type: provider
            });
            try {
              yield call(userService.postUserRegistrationComplite)
            } catch {

            }
          }
          if (isLogin) {
            analyticsEvents.userLoggedIn();
          }

          if (isFirstSession) {
            analyticsEvents.cohortEvent();
          }

        } catch (error) {
          yield put(actions.analyticsUserAuth.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.superOffer.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.superOffer.pending>
      ) {
        try {
          yield put(setters.setSuperOffer(action.payload))

          const superOffer: ISuperOffer = yield select(selectors.superOffer)
          sessionService.setSession({
            superOffer
          })
          yield put(actions.superOffer.fulfilled());
        } catch (error) {
          yield put(actions.superOffer.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.customBotCountUpdate.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.customBotCountUpdate.pending>
      ) {
        try {
          yield put(setters.setCustomBotCountUpdate(action.payload));

          yield put(actions.customBotCountUpdate.fulfilled());
        } catch (error) {
          yield put(actions.customBotCountUpdate.rejected(error as Error));
        }
      }
    ),

  ]);
}
