import { http } from 'src/services/AiVersations/http';
import * as types from './types';

export * from './types';

export const postCreateCheckoutSession = (
  params: types.IPostCreateCheckoutSessionParams
) =>
  http.post<types.IPostCreateCheckoutSessionData>(
    '/payments/create-checkout-session',
    params
  );

export const getSubscription = () =>
  http.get<types.IGetSubscriptionData>('/payments/subscription');

export const getWallet = () =>
  http.get<types.IGetWalletData>('/payments/wallet');
