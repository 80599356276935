import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import store from 'src/redux';
import router from 'src/router';
import { useAccountAction } from 'src/hooks/useAccount';
import { initializeAnalyticsUTM } from './services/analytics';
import { googleTagManagerInstall } from './services/analytics/providers/GoogleTagManager';
import { WebsiteDoesNotWork } from './components/WebsiteDoesNotWork';
import 'src/services/sentry';
import 'src/services/analytics/providers/Posthog';

import './App.css';
import './styles/theme.scss';

const search = new URLSearchParams(location.search)
const checkoutSession = search.get('checkout-session')
const checkoutSessionSuccess = search.get('checkout-session-success')
const isCheckoutSession = checkoutSession||checkoutSessionSuccess
const redirect = search.get('redirect')

if (!isCheckoutSession && !redirect){
  initializeAnalyticsUTM()
  googleTagManagerInstall()
}

const ReduxWrapper = () => {
  if (window.settings.WEBSITE_DOES_NOT_WORK) {
    return <WebsiteDoesNotWork />
  }

  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

interface CheckoutSession {
  url: string;
}


const Loading = ()=><div style={{display:'flex', height: '100vh', alignItems:'center', justifyContent:'center'}}><CircularProgress/></div>

function App() {
  const sessionSetupAction = useAccountAction.sessionSetup();

  React.useEffect(() => {
    if(!isCheckoutSession && !redirect){
      sessionSetupAction();
    }
  }, []);

  if(redirect){
    if (redirect === 'subscription-edit'){
      window.location.replace(window.settings.STRIPE_EDIT_SUBSCRIPTION_LINK)

    } else {
      window.location.search = ''
      setTimeout(()=>{
        window.location.reload()
      })
    }
    return <Loading/>
  }
  if (checkoutSession) {
    const data = JSON.parse(atob(checkoutSession)) as CheckoutSession

    location.href = data.url


    return <Loading/>
  }
  if (checkoutSessionSuccess){
    try{

      const data = JSON.parse(atob(checkoutSessionSuccess.replace('egI','eyJ'))) as { redirectUrl: string }

      if (data.redirectUrl){
        window.location.replace(data.redirectUrl)
      } else {
        throw new Error('')
      }
    } catch(e) {
      window.location.search = ''
      setTimeout(()=>{
        window.location.reload()
      })
    }

    return <Loading/>
  }

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3} autoHideDuration={1500}>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </div>
  );
}

export default ReduxWrapper;
