import * as account from './modules/account';
import * as dialog from './modules/dialog';
import * as payment from './modules/payment';
import * as character from './modules/character';
import * as gift from './modules/gift';

const reducers = {
  ...account.reducers,
  ...dialog.reducers,
  ...payment.reducers,
  ...character.reducers,
  ...gift.reducers,
};

export default reducers;
