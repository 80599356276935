import React, { Suspense } from 'react';
import { createBrowserRouter, Location, useRouteError } from 'react-router-dom';
import { RootLayout } from 'src/layouts/RootLayout';
// import { MainLayout } from "src/layouts/MainLayout";
// import { NavigationLayout } from "src/layouts/NavigationLayout";
import { FacebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents } from 'src/services/analytics/events';
import { SystemError } from 'src/components/SystemError';

const page = (name: string) => {
  const ComponentPage = React.lazy(() => import(`src/pages/${name}`));
  return (
    <Suspense>
      <ComponentPage />
    </Suspense>
  );
};

const PageError = ()=>{
  const error = useRouteError()
  const routeError = error as Error

  if (routeError?.name === 'ChunkLoadError'){
    const now = String(new Date().getTime()).slice(0,9)

    if (localStorage.getItem('ChunkLoadError') !== now){
      localStorage.setItem('ChunkLoadError', now)
      window.location.reload()

      return <></>
    }
  }

  console.error(error)

  return <SystemError />
}

export type RouterParams = {
  chatId?: string
}

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <PageError />,
    children: [
      {
        path: '',
        element: page('explore'),
        errorElement: <PageError />
      },
      {
        path: '/profile',
        element: page('profile'),
        errorElement: <PageError />
      },
      {
        path: '/characters/create',
        element: page('characters/create'),
        errorElement: <PageError />
      },
      {
        path: '/chats',
        element: page('chats'),
        errorElement: <PageError />
      },
      {
        path: '/chats/:chatId',
        element: page('chat'),
        errorElement: <PageError />
      },
      {
        path: 'bot/share/:share_code',
        element: page('botShare'),
        errorElement: <PageError />
      },
      {
        path: '*',
        element: page('pageNotFound'),
        errorElement: <PageError />
      },
    ],

  },

],
);


export interface RouterHistory {
  locations: Location[];
  getPrevLocation: () => Location | null;
}

export const routerHistory: RouterHistory = {
  locations: [],
  getPrevLocation: () => {
    const [current, prev] = routerHistory.locations;

    return prev;
  },
};

router.subscribe(({ location }) => {
  const [prev, prevBack] = routerHistory.locations;

  const isNotEqual =
    prev &&
    // prev.hash !== location.hash ||
    prev.pathname !== location.pathname;
    // prev.search !== location.search ||
    // prev.state !== location.state

  const notSaveToHistory = ['characters/create'];
  const isNotSaveToHistory = notSaveToHistory.find(path=>location.pathname.includes(path))

  if (prev && isNotEqual && !isNotSaveToHistory) {
    routerHistory.locations = [{ ...location }, ...routerHistory.locations];
  }

  if (!prev && !isNotSaveToHistory) {
    routerHistory.locations = [{ ...location }];
  }

  if (location?.pathname === prevBack?.pathname) {
    routerHistory.locations = routerHistory.locations.slice(2);
  }

  if (routerHistory.locations.length > 5) {
    routerHistory.locations = routerHistory.locations.slice(0, 5);
  }

  if (isNotEqual || !prev) {
    FacebookPixel.pageView();

    switch (location.pathname) {
      case '/':
        analyticsEvents.screenShown({ currentScreen: 'exploreScreen' });
        break;
      case '/chats':
        analyticsEvents.screenShown({ currentScreen: 'chatsListScreen' });
        break;
      default:
        break;
    }
  }
});

export default router;
