import React from 'react';
import { Modal, ModalTitle, ModalContent } from 'src/components/Modal';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount';
import { usePaymentAction } from 'src/hooks/usePayment';
import { getSession, setSession } from 'src/services/AiVersations/session';

import { getNewSubscriptionPrice } from 'src/services/AiVersations/products';

import styles from './ResubscriptionAlert.module.scss';

export function ResubscriptionAlert() {
  const { resubsription } = getSession();
  const [closed, setClosed] = React.useState(false);
  const isUser = useAccountSelect.isUser();
  const isConfirmAdult = useAccountSelect.confirmAdult();
  const subcription = useAccountSelect.subscription();
  const isOldServiceSubscription = useAccountSelect.isOldServiceSubscription();
  const isAlreadyResubscription = useAccountSelect.isAlreadyResubscription();
  const { isFulfilled } = useAccountAction.getSubscription.status();
  const postCreateCheckoutSession =
    usePaymentAction.postCreateCheckoutSession();
  const isSubscription = isFulfilled && subcription?.price_id;

  const handleClose = () => {
    setClosed(true);
    setSession({ resubsription: true });
  };

  const handleUpdate = () => {
    if (subcription) {
      setSession({ resubsription: true });
      postCreateCheckoutSession({
        price_id: getNewSubscriptionPrice(subcription.price_id) as string,
        dialogId: undefined,
        reason: 'resubscription',
        is_old: true,
      });
    }
  };

  if (
    closed ||
    resubsription ||
    !isUser ||
    !subcription ||
    !isSubscription ||
    !isConfirmAdult ||
    !isOldServiceSubscription ||
    !isAlreadyResubscription
  )
    return <></>;

  return (
    <Modal onClose={handleClose}>
      <ModalContent
        onClose={handleClose}
        className={styles.errorContainer}
        closeButtonClassName={styles.closeButton}
      >
        <Icon className={styles.errorIcon} name="Report" />

        <ModalTitle className={styles.errorTitle}>
          Please update your payment details
        </ModalTitle>

        <div className={styles.subtitle}>
          Due to changes in our legal entity we kindly ask you to resubscribe.
          New entity: “Romantic AI INC”.
        </div>
        <Button
          className={styles.errorButton}
          onClick={handleUpdate}
          variant="filled"
          color="secondary"
        >
          Update
        </Button>
      </ModalContent>
    </Modal>
  );
}
