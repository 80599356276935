import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  delay,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';

import { FacebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents, ESubscriptionReason } from 'src/services/analytics/events';
import { IResponse } from 'src/services/AiVersations/http';
import * as paymentService from 'src/services/AiVersations/payment';

import { setSession } from 'src/services/AiVersations/session';
import * as accountStore from 'src/redux/modules/account';
import * as dialogStore from 'src/redux/modules/dialog';

import { actions } from './actions';
import { setters } from './index';
import { getHeartProduct, getSubscriptionProduct } from 'src/services/AiVersations/products'

export default function* sagas() {
  yield all([
    takeLeading(
      actions.postCreateCheckoutSession.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postCreateCheckoutSession.pending>
      ) {
        try {
          const { price_id, dialogId, reason, is_old } = action.payload;
          const product = getSubscriptionProduct(price_id)
          console.debug(product)
          const queryReason = reason ? `&reason=${reason}` : '';
          const pathname = dialogId ? `chats/${dialogId}` : '';
          const success_url = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''
            }/${pathname}?modal=subscription${queryReason}`;

          const bot: dialogStore.IStateData['bot'] = yield select(
            dialogStore.selectors.bot
          );

          try {
            setSession({ superOffer: undefined })
            analyticsEvents.subscriptionCheckout({
              reason: reason as ESubscriptionReason,
              bot_name: bot?.name || '',
              is_personal: bot?.is_personal as boolean,
            })
            FacebookPixel.track('Purchase', { value: product?.analyticPrice, currency: 'USD' });
          } catch (e) {
            console.error(e)
          }

          const checkoutSession: IResponse<paymentService.IPostCreateCheckoutSessionData> =
            yield call(paymentService.postCreateCheckoutSession, {
              price_id,
              success_url: success_url,
              is_old,
            });

          yield delay(500);

          window.location.href = checkoutSession.data.url;

          yield put(actions.postCreateCheckoutSession.fulfilled());
        } catch (error) {
          yield put(actions.postCreateCheckoutSession.rejected(error as Error));
        }
      }
    ),
    takeLeading(
      actions.providerCreateCheckoutSession.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.providerCreateCheckoutSession.pending>
      ) {
        try {
          const { priceId, payload } = action.payload;
          const successUrl = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}?checkout-session-success=${payload}`

          const checkoutSession: IResponse<paymentService.IPostCreateCheckoutSessionData> =
            yield call(paymentService.postCreateCheckoutSession, {
              price_id: priceId,
              success_url: successUrl,
            });

          window.location.href = checkoutSession.data.url;

          yield put(actions.providerCreateCheckoutSession.fulfilled());
        } catch (error) {
          yield put(actions.providerCreateCheckoutSession.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.getSubscription.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getSubscription.pending>
      ) {
        try {
          const subscription: IResponse<paymentService.IGetSubscriptionData> =
            yield call(paymentService.getSubscription);

          yield put(setters.setSubscription(subscription.data));

          yield put(
            accountStore.actions.getSubscription({
              subscription: subscription.data,
            })
          );

          yield put(actions.getSubscription.fulfilled());
        } catch (error) {
          yield put(actions.getSubscription.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.postHeartCreateCheckoutSession.pending.toString(),
      function* (
        action: ActionPalyoad<
          typeof actions.postHeartCreateCheckoutSession.pending
        >
      ) {
        try {
          const { price_id, dialogId, reason, botName, isPersonal } = action.payload;
          const product = getHeartProduct(price_id);
          const modalName = 'paywall-heart-result';
          const queryReason = reason ? `&reason=${reason}` : '';
          const queryBotName = botName ? `&botName=${botName}` : '';
          const queryIsPersonal = isPersonal !== undefined ? `&isPersonal=${isPersonal}` : '';
          const queryHeartCount = `&heartCount=${product?.count}`;
          const queryPriceId = `&priceId=${price_id}`;
          const pathname = dialogId ? `chats/${dialogId}` : '';
          const query = `?modal=${modalName}${queryReason}${queryHeartCount}${queryPriceId}${queryBotName}${queryIsPersonal}`;
          const success_url = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''
            }/${pathname}${query}`;

          const checkoutSession: IResponse<paymentService.IPostCreateCheckoutSessionData> =
            yield call(paymentService.postCreateCheckoutSession, {
              price_id,
              success_url: success_url,
            });


          location.replace(checkoutSession.data.url);

          yield put(actions.postHeartCreateCheckoutSession.fulfilled());
        } catch (error) {
          yield put(
            actions.postHeartCreateCheckoutSession.rejected(error as Error)
          );
        }
      }
    ),
  ]);
}