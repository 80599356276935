import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import {
  builderSagaCommunications,
  clearAllStateCommunications,
} from 'src/helpers/redux';
import { actions as moduleActions } from './actions';
import * as moduleState from './state';

import moduleSagas from './sagas';
import * as utils from './utils';

export const slice = createSlice({
  name: moduleState.PREFIX,
  initialState: moduleState.initialState,
  reducers: {
    setDialogs(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogs']>
    ){
      state.data.dialogs = action.payload
    },
    setDialogHistory(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogHistory']>
    ){
      state.data.dialogHistory = action.payload
    },
    setDialogHistoryAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogHistory']>
    ){
      state.data.dialogHistory = {
        ...state.data.dialogHistory,
        ...action.payload,
        messages: [...state.data.dialogHistory.messages, ...action.payload.messages,],
      }
    },
    setBotMessageTyping(state: moduleState.IState,  action: PayloadAction<moduleState.IStateData['isTyping']>){
      state.data.isTyping = action.payload
    },
    setClearGetDialogHistory(state: moduleState.IState,) {
      state.communication.getDialogHistory.state = 0
    },
    setBot(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['bot']>
    ) {
      state.data.bot = action.payload;
    },

    setUserMessage(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = [...messages, ...state.data.dialogHistory.messages];
        }
      }
    },

    setBotMessage(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = [...messages, ...state.data.dialogHistory.messages];
        }
      }
    },

    setAppendChatUserMessage(
      state: moduleState.IState,
      action: PayloadAction<{
        dialogId: moduleState.IStateData['dialogHistory']['id'];
        messages: moduleState.IStateData['dialogHistory']['messages'];
      }>
    ) {
      const { dialogId, messages } = action.payload;

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === dialogId) {
          state.data.dialogHistory.messages = [...messages, ...state.data.dialogHistory.messages];
        }
      }
    },
    setMessageUnblur(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['dialogHistory']['messages'][number]>
    ) {
      const { id, user } = action.payload;

      const unblurMessageMap = (
        message: moduleState.IStateData['dialogHistory']['messages'][number]
      ) => {
        if (message.id === id) {
          return {
            ...message,
            blurred: false,
            unblur_cost: null,
          };
        }
        return message;
      };

      if (state.data.dialogHistory) {
        if (state.data.dialogHistory.id === user.id) { // !!!!
          state.data.dialogHistory.messages =
            state.data.dialogHistory.messages.map(unblurMessageMap);
        }
      }
    },

    setHighDemand(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['highDemand']>
    ) {
      state.data.highDemand = action.payload;
    },

    setHighDemandAlertOpen(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['isHighDemandAlertOpen']>
    ) {
      state.data.isHighDemandAlertOpen = action.payload;
    },

    setWriteMessage(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['writeMessage']>
    ) {
      state.data.writeMessage = action.payload;
    },
    setBotSearch(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botSearch']>
    ) {
      state.data.botSearch = action.payload;
    },
    setBotRecent(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botRecent']>
    ) {
      state.data.botRecent = action.payload;
    },
    setBotRecentAppend(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botRecent']>
    ) {
      const { hasMore, items } = action.payload
      state.data.botRecent.hasMore = hasMore;
      state.data.botRecent.items.push(...items)
    },
    setBotCategories(
      state: moduleState.IState,
      action: PayloadAction<moduleState.IStateData['botCategories']>
    ) {
      state.data.botCategories = action.payload;
    },
    setBotCategoriesSlug(
      state: moduleState.IState,
      action: PayloadAction<{
        category_slug:string;
        bots: moduleState.IStateData['botRecent']['items']
      }>
    ) {
      const { bots, category_slug } = action.payload
      const currentCategory = state.data.botCategories.categories[category_slug]
      if (currentCategory){
        currentCategory.push(...bots)
      }
     },
    setClear(state: moduleState.IState) {
      state.data = moduleState.getInitialData();
      clearAllStateCommunications(state.communication);
    },
  },
  extraReducers: (builder) => {
    builderSagaCommunications<moduleState.IState>(builder, moduleActions);
  },
});

export const setters = slice.actions;
export const actions = moduleActions;
export const reducers = { [moduleState.PREFIX]: slice.reducer };
export const sagas = moduleSagas;
export const state = moduleState;
export * from './selectors';
export * from './state';
