import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';
import { getSession } from 'src/services/AiVersations/session';
import * as giftServiceTypes from 'src/services/AiVersations/gift/types';

export interface IStateData {
  gift?: giftServiceTypes.GetGiftData;
  // tooltipHidden: boolean;
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  const session = getSession()
  return {
    gift: undefined,
    // tooltipHidden: Boolean(session.chatStoreTooltipClosed)
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
