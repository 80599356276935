import { addHours, isFuture } from 'date-fns';
import * as sessionService from 'src/services/AiVersations/session';
import * as dialogServiceTypes from 'src/services/AiVersations/dialog/types';

export const getHighDemand = (history = false) => {
  const dateNow = new Date().getTime();
  const maxMessagesCount = 10;
  // const timerHours = 2; // лимит по времени последнего сообщения
  try {
    const { messagesTimeHistory = [] } = sessionService.getSession();

    const isMax = messagesTimeHistory.length >= maxMessagesCount;
    // const limitedMessage = Number(messagesTimeHistory[0]);
    // const isValidLimitedMessage = limitedMessage && !isNaN(limitedMessage);
    if (isMax){
      return true
    }
    // if (isMax && isValidLimitedMessage) {
    //   const limit = addHours(new Date(limitedMessage), timerHours);
    //   const isLimited = isFuture(limit);

    //   if (isLimited) {
    //     return limit;
    //   }
    // }
    if (history) {
      messagesTimeHistory.push(dateNow);

      if (isMax) {
        messagesTimeHistory.splice(0, 1);
      }

      sessionService.setSession({ messagesTimeHistory });
    }
  } catch (e) {
    console.error(e);
  }
  return false;
};

export const createMessage = (
  text: string,
  type?: 'text'
): dialogServiceTypes.IMessage => ({
  id: new Date().getTime(),
  type: 'text',
  text: text,
  user: {
    id: new Date().getTime(),
    type: 'user',
  },
  date: new Date().toISOString(),
  blurred: false,
  unblur_cost: null,
});
