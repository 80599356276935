import { http } from 'src/services/AiVersations/http';
import * as types from './types';

export const getDialogCategorized = () =>
  http.get<types.IGetDialogCategorizedData>('/dialog/categorized');

export const getDialogs = () =>
  http.get<types.IGetDialogCategorizedData>('/dialog');

export const getDialogHistory = ({
  dialogId,
  ...params
}: types.IGetDialogHistoryParams) =>
  http.get<types.IGetDialogHistoryData>(`/dialog/${dialogId}/history`, {
    params,
  });


export const postDialogMessage = ({
  dialogId,
  ...data
}: types.IPostDialogMessageParams) =>
  http.post<types.IPostDialogMessageData>(`/dialog/${dialogId}/message`, data);

export const postDialogMessageUnblur = ({
  messageId,
}: types.IPostDialogMessageUnblurParams) =>
  http.post<types.IPostDialogMessageUnblurData>(`/message/${messageId}/unblur`);

export const postDialogVisit = ({
  target_user_id,
  ...data
}: types.IPostDialogVisitParams) =>
  http.post<types.IPostDialogVisitData>(`/dialog/${target_user_id}/is_visit`, data);

export * from './types';
