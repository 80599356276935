import React from 'react';
import classNames from 'classnames/bind';


import { Button } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import { useModalPaywallHeart, ECoinsPaywallReason } from 'src/hooks/route/useModalPaywallHeart';
import { useAccountSelect } from 'src/hooks/useAccount';

import styles from './WalletButton.module.scss';

const sx = classNames.bind(styles)

export {
  ECoinsPaywallReason
}
export interface IWalletButtonProps {
  reason: ECoinsPaywallReason
}

export const WalletButton = (props: IWalletButtonProps) => {
  const { reason } = props
  const { open } = useModalPaywallHeart()
  const wallet = useAccountSelect.wallet();

  return (
    <Button
      className={sx('wallet')}
      color="secondary"
      variant="outlined"
      size="md"
      onClick={() => open({ reason: reason })}
    >
      <Icon name="Heart" /> {wallet?.balance || 0}
    </Button>

  )
}