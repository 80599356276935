import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';

import * as paymentServiceTypes from 'src/services/AiVersations/payment/types';

export interface IStateData {
  checkoutSession?: paymentServiceTypes.IPostCreateCheckoutSessionData;
  subscription?: paymentServiceTypes.IGetSubscriptionData;
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  return {
    checkoutSession: undefined,
    subscription: undefined,
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
