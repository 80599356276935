import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames/bind';

import { SubcribleBanner } from 'src//components/SubcribleBanner';
import { CustomBotBanner } from 'src//components/CustomBotBanner';
import { Icon, IIconProps } from 'src/components/Icon';

import { useMediaPhone } from 'src/hooks/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styles from './Navigation.module.scss';
import { useAccountSelect } from 'src/hooks/useAccount';
import { ab_subscription_paywall_1_15_0_group } from 'src/services/AiVersations/user';

const sx = classNames.bind(styles);

interface INavItemProps extends NavLinkProps {
  icon?: IIconProps['name'];
  iconProps?: IIconProps;
  label?: string;
}

export const NavItem = (props: INavItemProps) => {
  const { icon, iconProps, to, label, onClick } = props;

  return (
    <NavLink
      to={to}
      className={({ isActive }) => sx('item', { active: isActive })}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
    >
      {icon && (
        <div className={sx('item-icon')}>
          <Icon {...iconProps} name={icon} />
        </div>
      )}
      {label && <div className={sx('item-label')}>{label}</div>}
    </NavLink>
  );
};

export const Navigation = () => {
  const isPhone = useMediaPhone();
  const location = useLocation();
  const pageRef = React.useRef<HTMLDivElement | null>(null);
  const isChat = location.pathname === '/chats';
  const isCharacters = location.pathname.includes('/characters/');
  const isExplore = location.pathname === '/';
  const isView = !isPhone && [isExplore, isChat, isCharacters].includes(true);
  const user = useAccountSelect.user()
  const isSubscribled = useAccountSelect.isSubscribled();
  const isSuperCustom = user?.custom_bot === 'new_custom';
  const analyticsUTMRetargeting = useAccountSelect.analyticsUTMRetargeting();
  const isDuscount = !isSubscribled

  return (
    <SwitchTransition>
      <CSSTransition
        key={String(isView)}
        nodeRef={pageRef}
        timeout={75}
        classNames={'page'}
        unmountOnExit
      >
        {() => (
          <div ref={pageRef} className={`page`}>
            {isView && (
              <div ref={pageRef} className={sx('container')}>
                <div className={sx('list')}>
                  <NavItem icon="Home" to="/" label="Explore" />
                  <NavItem icon="Messages" to="/chats" label="Chats" />
                </div>
                <div className={sx('banners')}>
                  {/* {
                    (!isSuperCustom || isDuscount) && (
                      <CustomBotBanner />
                    )
                  } */}
                  <SubcribleBanner />
                </div>
                <div className={sx('footer')}>

                </div>
              </div>
            )}
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  );
};
