import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';

import * as dialogService from 'src/services/AiVersations/dialog/types';
import * as botService from 'src/services/AiVersations/bot/types';

import * as utils from './utils';
import { getSession } from 'src/services/AiVersations/session';

// export interface IBot extends dialogService.IBot {
//   lastMessage?: dialogService.IMessage;
// }

// export interface ICategory extends dialogService.ICategory {
//   bots: IBot[];
// }

// export interface IChat extends dialogService.IGetDialogHistoryParams {
//   messages: dialogService.IMessage[];
//   bot: IBot;
//   isTyping?: boolean;
//   bot_monetization_parameters: dialogService.IGetDialogHistoryData['bot_monetization_parameters']
// }

export interface IStateData {
  // categories: dialogService.IGetDialogCategorizedData['categories'];
  // categoriesExploring: ICategory[];
  // recentChats: ICategory;
  // categoriesDetails: dialogService.IGetDialogCategorizedData['categories_mapping'];
  // bots: dialogService.IGetDialogCategorizedData['bots'];
  // messages: dialogService.IGetDialogCategorizedData['messages'];
  bot: botService.IBot
  dialogs: dialogService.IGetDialogsData
  dialogHistory: dialogService.IGetDialogHistoryData & {hasMore?:boolean};
  highDemand: ReturnType<typeof utils.getHighDemand>;
  isHighDemandAlertOpen: boolean;
  writeMessage: Record<string, string>;
  isTyping?: boolean;
  botSearch: botService.IPostBotSearchData;
  botRecent: {
    hasMore?: boolean;
    items: botService.IGetBotRecentData
  }
  botCategories: botService.IGetBotCategoriesData
  botCategoriesSlug: botService.IGetBotCategoriesSlugData
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  const session = getSession();

  return {
    // categories: [],
    // recentChats: {
    //   id: 0,
    //   category_type: 'recent',
    //   description: '',
    //   is_system_category: true,
    //   name: 'Recent',
    //   bots: [],
    // },
    // categoriesExploring: [],
    // categoriesDetails: [],
    // bots: [],
    // messages: [],
    isTyping: false,
    bot: {
      id: 0,
      nickname: '',
      first_name: '',
      name:'',
      last_name: '',
      gender: '',
      age: 0,
      country: '',
      city: '',
      description: '',
      traits: [],
      mood: {
        shyness: 0,
        optimism: 0,
        ordinariness: 0
      }
    },
    dialogHistory: {
      id: 0,
      count: 0,
      filters: {
        count: 0,
        from_message_id: 0,
        reverse: false,
      },
      messages: []
    },
    highDemand: utils.getHighDemand(),
    isHighDemandAlertOpen: false,
    writeMessage: session.writeMessage || {},
    dialogs: [],
    botSearch: {
      data: [],
      offset: 0,
      limit: 0,
    },
    botRecent: {
      hasMore: false,
      items: []
    },
    botCategories: {
      categories_mapping: [],
      categories: {}
    },
    botCategoriesSlug: []
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
