import { isStaging } from 'src/utils/environment';

export interface ISubscriptionProduct {
  id: string;
  perWeekPrice?: string;
  totalPrice?: string;
  period: string;
  bridgeTitle?: string;
  analyticPrice: number;
  productId: ESubscriptionProductIds;
  isShow?: boolean;
  newPaywall?: {
    title: string;
    subtitle: string;
    previousPrice?: string;
    savePercent?: string;
    currentPrice?: string;
  }
}

export enum ESubscriptionProductIds {
  weekly = 'weekly',
  monthly = 'monthly',
  month3 = 'month3',
  yearly = 'yearly',
  lifetime = 'lifetime',
}

const oldSubscriptionProducts: { id: string, productId: ESubscriptionProductIds }[] = [
  {
    id: isStaging
      ? '1_week_new_web_subscription_699'
      : '1_week_web_subscription_699',
    productId: ESubscriptionProductIds.weekly,
  },
  {
    id: '1_month_web_subscription_1499',
    productId: ESubscriptionProductIds.monthly,
  },
  {
    id: '1_year_web_subscription_4499',
    productId: ESubscriptionProductIds.yearly,
  },
];

export const subscriptionProducts: ISubscriptionProduct[] = [
  {
    id: isStaging ? 'price_1NlYJdAd2QHgfDVfGaALyZ7F' : 'price_1NsQOVAd2QHgfDVfytGaOuFE',
    perWeekPrice: '$6.99',
    period: 'Billed Weekly',
    bridgeTitle: 'Popular',
    analyticPrice: 6.99,
    totalPrice: '$6.99',
    productId: ESubscriptionProductIds.weekly,
    newPaywall: {
      title: 'Weekly subscription',
      subtitle: 'price per week',
      previousPrice: '',
      savePercent: '',
    }
  },
  {
    id: isStaging ? 'price_1NlYIfAd2QHgfDVfLV4OSyvH' : 'price_1NsQOZAd2QHgfDVfQTVMJIgp',
    perWeekPrice: '$3.49',
    totalPrice: '$14.99',
    analyticPrice: 14.99,
    period: 'Billed Monthly',
    bridgeTitle: 'SAVE 50%',
    productId: ESubscriptionProductIds.monthly,
    isShow: false,
    newPaywall: {
      title: 'Monthly subscription',
      subtitle: 'per week',
      previousPrice: '$6.99',
      savePercent: '50%',
      currentPrice: '$3.49',
    }
  },
  {
    id: isStaging ? 'price_1NlYJ4Ad2QHgfDVfZSy7TGFS' : 'price_1NsQOXAd2QHgfDVf76qTZnKs',
    perWeekPrice: '$0.86',
    totalPrice: '$44.99',
    analyticPrice: 44.99,
    period: 'Billed Yearly',
    bridgeTitle: 'SAVE 87%',
    productId: ESubscriptionProductIds.yearly,
    newPaywall: {
      title: 'Yearly payment',
      subtitle: 'per week',
      previousPrice: '$6.99',
      currentPrice: '$0,86',
      savePercent: '87%',
    }
  },
  {
    id: isStaging ? 'price_1O1mUGAd2QHgfDVfKYYwlCQo' : 'price_1O1mWaAd2QHgfDVflR8COB7T',
    totalPrice: '$99.99',
    analyticPrice: 99.99,
    period: 'One time purchase',
    productId: ESubscriptionProductIds.lifetime,
    newPaywall: {
      title: 'Lifetime purchase',
      subtitle: 'access to premium forever',
      previousPrice: '',
      savePercent: '',
    }
  },
];



export const subscriptionProductsRed: ISubscriptionProduct[] = [
  {
    id: isStaging ? 'price_1PL54BKYfrrHAZLX5SW2YMHu' : 'price_1PL5WpKYfrrHAZLXeoy3uJQI',
    perWeekPrice: '',
    period: '',
    bridgeTitle: '',
    analyticPrice: 6.99,
    totalPrice: '$6.99',

    productId: ESubscriptionProductIds.weekly,
    newPaywall: {
      title: 'Weekly',
      subtitle: 'every 7 day',
      previousPrice: '$13.99',
      savePercent: '50%',
    }
  },
  {
    id: isStaging ? 'price_1PL56TKYfrrHAZLXW66Sp3QV' : 'price_1PL5WwKYfrrHAZLXbHgQ5jJq',
    perWeekPrice: '',
    totalPrice: '$14.99',
    analyticPrice: 14.99,
    period: '',
    bridgeTitle: 'Popular',
    productId: ESubscriptionProductIds.monthly,
    newPaywall: {
      title: 'Monthly',
      subtitle: 'every month',
      previousPrice: '$59.99',
      savePercent: '75%',
      currentPrice: '',
    }
  },
  {
    id: isStaging ? 'price_1PL57qKYfrrHAZLXkBsAhHgd' : 'price_1PL5X1KYfrrHAZLXJ06mMoVD',
    perWeekPrice: '',
    totalPrice: '$44.99',
    analyticPrice: 44.99,
    period: '',
    bridgeTitle: '',

    productId: ESubscriptionProductIds.yearly,
    newPaywall: {
      title: 'Yearly',
      subtitle: 'every year',
      previousPrice: '$179.99',
      currentPrice: '',
      savePercent: '75%',
    }
  },
  {
    id: isStaging ? 'price_1O1mUGAd2QHgfDVfKYYwlCQo' : 'price_1O1mWaAd2QHgfDVflR8COB7T',
    totalPrice: '$99.99',
    analyticPrice: 99.99,
    period: 'One time purchase',
    productId: ESubscriptionProductIds.lifetime,
    isShow: false,
    bridgeTitle: '',
    newPaywall: {
      title: 'Lifetime purchase',
      subtitle: 'access to premium forever',
      previousPrice: '',
      savePercent: '85%',
    }
  },
];


export const subscriptionProductsRedB: ISubscriptionProduct[] = [
  {
    id: isStaging ? 'price_1OucIEAd2QHgfDVfleVdsAQ5' : 'price_1OubQzAd2QHgfDVf68JZIRQ9',
    perWeekPrice: '',
    period: '',
    bridgeTitle: '',
    analyticPrice: 7.99,
    totalPrice: '$7.99',

    productId: ESubscriptionProductIds.weekly,
    newPaywall: {
      title: 'Weekly',
      subtitle: 'every 7 day',
      previousPrice: '$14.99',
      savePercent: '50%',
    }
  },
  {
    id: isStaging ? 'price_1OucJkAd2QHgfDVfMF53K7j1' : 'price_1OubUfAd2QHgfDVfGkFNYH7M',
    perWeekPrice: '',
    totalPrice: '$17.99',
    analyticPrice: 17.99,
    period: '',
    bridgeTitle: 'Popular',
    productId: ESubscriptionProductIds.monthly,
    newPaywall: {
      title: 'Monthly',
      subtitle: 'every month',
      previousPrice: '$71.99',
      savePercent: '75%',
      currentPrice: '',
    }
  },
  {
    id: isStaging ? 'price_1OucKgAd2QHgfDVfZoq7YN8g' : 'price_1OubWCAd2QHgfDVfBP83eqIX',
    perWeekPrice: '',
    totalPrice: '$49.99',
    analyticPrice: 49.99,
    period: '',
    bridgeTitle: '',

    productId: ESubscriptionProductIds.yearly,
    newPaywall: {
      title: 'Yearly',
      subtitle: 'every year',
      previousPrice: '$333.99',
      currentPrice: '',
      savePercent: '85%',
    }
  },
  {
    id: isStaging ? 'price_1O1mUGAd2QHgfDVfKYYwlCQo' : 'price_1O1mWaAd2QHgfDVflR8COB7T',
    totalPrice: '$99.99',
    analyticPrice: 99.99,
    period: 'One time purchase',
    productId: ESubscriptionProductIds.lifetime,
    isShow: false,
    bridgeTitle: '',
    newPaywall: {
      title: 'Lifetime purchase',
      subtitle: 'access to premium forever',
      previousPrice: '',
      savePercent: '85%',
    }
  },
];

export const subscriptionProductsRedC: ISubscriptionProduct[] = [
  {
    id: isStaging ? 'price_1OucJ3Ad2QHgfDVfBYtmWS2y' : 'price_1OubS7Ad2QHgfDVfWL7J8M3c',
    perWeekPrice: '',
    period: '',
    bridgeTitle: '',
    analyticPrice: 9.99,
    totalPrice: '$9.99',

    productId: ESubscriptionProductIds.weekly,
    newPaywall: {
      title: 'Weekly',
      subtitle: 'every 7 day',
      previousPrice: '$19,99',
      savePercent: '50%',
    }
  },
  {
    id: isStaging ? 'price_1OucK0Ad2QHgfDVfdNyM6wnK' : 'price_1OubVJAd2QHgfDVfmPayFR5l',
    perWeekPrice: '',
    totalPrice: '$19.99',
    analyticPrice: 19.99,
    period: '',
    bridgeTitle: 'Popular',
    productId: ESubscriptionProductIds.monthly,
    newPaywall: {
      title: 'Monthly',
      subtitle: 'every month',
      previousPrice: '$79.99',
      savePercent: '75%',
      currentPrice: '',
    }
  },
  {
    id: isStaging ? 'price_1OucKwAd2QHgfDVfuFG2pIce' : 'price_1OubWeAd2QHgfDVf0bItOEKG',
    perWeekPrice: '',
    totalPrice: '$55.99',
    analyticPrice: 55.99,
    period: '',
    bridgeTitle: '',
    productId: ESubscriptionProductIds.yearly,
    newPaywall: {
      title: 'Yearly',
      subtitle: 'every year',
      previousPrice: '$374.99',
      currentPrice: '',
      savePercent: '85%',
    }
  },
  {
    isShow: false,
    id: isStaging ? 'price_1O1mUGAd2QHgfDVfKYYwlCQo' : 'price_1O1mWaAd2QHgfDVflR8COB7T',
    totalPrice: '$99.99',
    analyticPrice: 99.99,
    period: 'One time purchase',
    productId: ESubscriptionProductIds.lifetime,
    bridgeTitle: '',
    newPaywall: {
      title: 'Lifetime purchase',
      subtitle: 'access to premium forever',
      previousPrice: '',
      savePercent: '85%',
    }
  },
];

export const subscriptionProductsRedD: ISubscriptionProduct[] = [
  {
    id: isStaging ? 'price_1OwDevAd2QHgfDVfbiRFSUX8' : 'price_1OwDk6Ad2QHgfDVfSTbSJF8F',
    perWeekPrice: '',
    period: '',
    bridgeTitle: '',
    analyticPrice: 14.99,
    totalPrice: '$14.99',

    productId: ESubscriptionProductIds.weekly,
    newPaywall: {
      title: 'Weekly',
      subtitle: 'every 7 day',
      previousPrice: '$29,99',
      savePercent: '50%',
    }
  },
  {
    id: isStaging ? 'price_1OwDgOAd2QHgfDVfZNRCPdhz' : 'price_1OwDx3Ad2QHgfDVfLDVg7pvX',
    perWeekPrice: '',
    totalPrice: '$29.99',
    analyticPrice: 29.99,
    period: '',
    bridgeTitle: 'Popular',
    productId: ESubscriptionProductIds.monthly,
    newPaywall: {
      title: 'Monthly',
      subtitle: 'every month',
      previousPrice: '$99.99',
      savePercent: '75%',
      currentPrice: '',
    }
  },
  {
    id: isStaging ? 'price_1OwDhXAd2QHgfDVfTOF2iLk6' : 'price_1OwDxjAd2QHgfDVfJ3rgE1C8',
    perWeekPrice: '',
    totalPrice: '89.99',
    analyticPrice: 89.99,
    period: '',
    bridgeTitle: '',
    productId: ESubscriptionProductIds.yearly,
    newPaywall: {
      title: 'Yearly',
      subtitle: 'every year',
      previousPrice: '$349.99',
      currentPrice: '',
      savePercent: '85%',
    }
  },
  {
    isShow: false,
    id: isStaging ? 'price_1O1mUGAd2QHgfDVfKYYwlCQo' : 'price_1O1mWaAd2QHgfDVflR8COB7T',
    totalPrice: '$99.99',
    analyticPrice: 99.99,
    period: 'One time purchase',
    productId: ESubscriptionProductIds.lifetime,
    bridgeTitle: '',
    newPaywall: {
      title: 'Lifetime purchase',
      subtitle: 'access to premium forever',
      previousPrice: '',
      savePercent: '85%',
    }
  },
];

export const subscriptionProductsSuperOffer:ISubscriptionProduct = {
  id: isStaging ? 'price_1P3z44Ad2QHgfDVfXypL5fhH' : 'price_1P3z80Ad2QHgfDVfPyzx6Bbt',
  analyticPrice: 14.99,
  productId: ESubscriptionProductIds.month3,
  period: '',
}

export const isOldSubscriptionPrice = (price_id: string) =>
  Boolean(oldSubscriptionProducts.find((item) => item.id === price_id));
export const getNewSubscriptionPrice = (old_price_id: string) => {
  const oldProduct = oldSubscriptionProducts.find(
    (item) => item.id === old_price_id
  );
  const newProduct = subscriptionProducts.find(
    (item) => item.productId === oldProduct?.productId
  );

  return newProduct?.id;
};

const allSubscriptionProducts = [
...subscriptionProducts,
...subscriptionProductsRed,
...subscriptionProductsRedB,
...subscriptionProductsRedC,
...subscriptionProductsRedD,
subscriptionProductsSuperOffer,
]
export const getSubscriptionProduct = (price_id: string) => {
  if (isOldSubscriptionPrice(price_id)) {
    const id = getNewSubscriptionPrice(price_id);

    return allSubscriptionProducts.find(i => i.id === id)
  }
  return allSubscriptionProducts.find(i => i.id === price_id)
}


export interface IHeartProduct {
  id: string;
  count: string | number;
  price: number;
  savePercent?: number;
}

export const heartProducts: IHeartProduct[] = [
  {
    id: isStaging ? 'price_1PL5ITKYfrrHAZLXMr27rksW' : 'price_1PL5X8KYfrrHAZLX6YWJ9Pjk',
    count: '100',
    price: 4.99,
  },
  {
    id: isStaging ? 'price_1PL5JEKYfrrHAZLXeUXQoZ66' : 'price_1PL5XCKYfrrHAZLXrdO6HCjU',
    count: '300',
    price: 9.99,
    savePercent: 33,
  },
  {
    id: isStaging ? 'price_1PL5JnKYfrrHAZLXP90ym0Fw' : 'price_1PL5XFKYfrrHAZLXWk5hIlHB',
    count: '500',
    price: 15.99,
    savePercent: 40,
  },
  {
    id: isStaging ? 'price_1PL5KIKYfrrHAZLX3UwdmcRA' : 'price_1PL5XIKYfrrHAZLXuXAripAZ',
    count: '1,000',
    price: 19.99,
    savePercent: 60,
  }
]


const allHeartsProducts = [
  ...heartProducts,
]

export const getHeartProduct = (price_id: string) => {
  return allHeartsProducts.find(item => item.id === price_id);
}

export type SetShowProductsStatuses = {
  [key in `${ESubscriptionProductIds}`]?: { [key in keyof ISubscriptionProduct]?: ISubscriptionProduct[key] };
};
export const setSubscriptionProductsShow = (
  products: ISubscriptionProduct[],
  statuses: SetShowProductsStatuses
): ISubscriptionProduct[] => products.map((product) => ({
  ...product,
  ...(statuses[product.productId] !== undefined ?
    statuses[product.productId] :
    {}
  )
}))

