import { subDays, isAfter } from 'date-fns';
import { createSelector } from 'reselect';
import { createCommunicationSelectors } from 'src/helpers/redux';
import { IState, PREFIX, TGlobalStateWithService } from './state';
import { actions } from './actions';

import { isOldSubscriptionPrice } from 'src/services/AiVersations/products';
import { isStaging } from 'src/utils/environment';

import { hasAnalyticUTMRetargeting } from 'src/services/analytics';

export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data;

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication;

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions);

const selectSubscription = createSelector(
  selectData,
  (state) => state.subscription
);

const selectIsOldServiceSubscription = createSelector(
  selectSubscription,
  (subscription) => {
    if (subscription) {
      const { price_id } = subscription;

      return isOldSubscriptionPrice(price_id);
    }

    return false;
  }
);

const selectIsAlreadyResubscription = createSelector(
  [selectSubscription, selectIsOldServiceSubscription],
  (subscription, isOldService) => {
    if (subscription && isOldService) {
      const { end_datetime } = subscription;
      const currentSubscriptionEndDate = new Date(end_datetime);
      const currentDate = new Date();
      const alreadyResubscriptionDate = subDays(
        currentSubscriptionEndDate,
        isStaging ? 8 : 3
      ); // -3 days
      const isAlready = isAfter(currentDate, alreadyResubscriptionDate);

      return isAlready;
    }
    return false;
  }
);

export const selectors = {
  clientToken: createSelector(selectData, (state) => state.clientToken),
  idToken: createSelector(selectData, (state) => state.idToken),
  subscription: selectSubscription,
  subscriptionEmail: createSelector(selectData, (state) => state.subscriptionEmail),
  isSubscribled: createSelector(selectSubscription, (subscription) => {
    if (subscription) {
      if (subscription?.is_lifetime) return true;
      return isAfter(new Date(subscription.end_datetime), new Date());
    }

    return false;
  }),
  isOldServiceSubscription: selectIsOldServiceSubscription,
  isAlreadyResubscription: selectIsAlreadyResubscription,
  profile: createSelector(selectData, (state) => state.profile),
  user: createSelector(selectData, (state) => state.user),
  confirmAdult: createSelector(selectData, (state) => state.confirmAdult),
  wallet: createSelector(selectData, (state) => state.wallet),
  isClient: createSelector(selectData, (state) => Boolean(state.clientToken)),
  isUser: createSelector(selectData, (state) => Boolean(state.idToken)),
  emailVerification: createSelector(selectData, (state) => state.emailVerification),
  analyticsUTM: createSelector(selectData, (state) => state.analyticsUTM),
  analyticsUTMRetargeting: createSelector(selectData, (state) => hasAnalyticUTMRetargeting(state.analyticsUTM)),
  superOffer: createSelector(selectData, state=>state.superOffer),
  customBotCount:createSelector(selectData, state=>state.user?.new_custom_bot_count || 0)
};
