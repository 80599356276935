import React from 'react';
import { ModalPreloader } from 'src/components/Modal';
import { useAccountSelect } from 'src/hooks/useAccount';

const PaywallSuperOfferLazy = React.lazy(() => import('./PaywallSuperOffer'))

export const PaywallSuperOfferAsync = () => {
  const user = useAccountSelect.user()
  const superOffer = useAccountSelect.superOffer()
  console.debug(superOffer)
  if (
    user?.ab_subscription_paywall_02_2024_group === 'subscription_paywall_02_2024_b' &&
    superOffer?.status === 'pending'
  ){
    return (
      <React.Suspense fallback={<ModalPreloader />}>
        <PaywallSuperOfferLazy />
      </React.Suspense>
    )
  }

  return <></>
}