import React, { Component } from 'react';
import classNames from 'classnames';

enum EIconNames {
  'AddChat' = 'AddChat',
  'Back' = 'Back',
  'ChatProfile' = 'ChatProfile',
  'CheckMark' = 'CheckMark',
  'CloseRound' = 'CloseRound',
  'Copy' = 'Copy',
  'Crown' = 'Crown',
  'CrownCircle' = 'CrownCircle',
  'Edit' = 'Edit',
  'Heart' = 'Heart',
  'Home' = 'Home',
  'ListItemDot' = 'ListItemDot',
  'Flash' = 'Flash',
  'Messages' = 'Messages',
  'Message' = 'Message',
  'NoNotifications' = 'NoNotifications',
  'Paywall/PremiumContent' = 'Paywall/PremiumContent',
  'Paywall/RomanticMode' = 'Paywall/RomanticMode',
  'Plus' = 'Plus',
  'ProfileAdd' = 'ProfileAdd',
  'Report' = 'Report',
  'ReportFilled' = 'ReportFilled',
  'Send' = 'Send',
  'Social/Apple' = 'Social/Apple',
  'Social/Google' = 'Social/Google',
  'Social/Reddit' = 'Social/Reddit',
  'Social/Discord' = 'Social/Discord',
  'Unlock' = 'Unlock',
  'Photo' = 'Photo',
  'PhotoAdd' = 'PhotoAdd',
  'Close' = 'Close',
  'Personal' = 'Personal',
  'CheckListItem' = 'CheckListItem',
  'Picture' = 'Picture',
  'Random' = 'Random',
  'Clock' = 'Clock',
  'Search' = 'Search',
  'CardPay' = 'CardPay',
  'Gift' = 'Gift',
  'Share' = 'Share',
  'VirusChecked' = 'VirusChecked',
  'effects/character-swiper-bg-elipse-1' = 'effects/character-swiper-bg-elipse-1',
  'effects/character-swiper-bg-elipse-2' = 'effects/character-swiper-bg-elipse-2',
  'effects/supercustom-circle-1' = 'effects/supercustom-circle-1',
  'effects/supercustom-circle-2' = 'effects/supercustom-circle-2',
  'effects/supercustom-circle-3' = 'effects/supercustom-circle-3',
}

const getIcon = (name: string) => {
  switch (name) {
    case EIconNames['AddChat']:
      return import(`./svg/AddChat.svg`);
    case EIconNames['Back']:
      return import(`./svg/Back.svg`);
    case EIconNames['ChatProfile']:
      return import(`./svg/ChatProfile.svg`);
    case EIconNames['CheckMark']:
      return import(`./svg/CheckMark.svg`);
    case EIconNames['Copy']:
      return import(`./svg/Copy.svg`);
    case EIconNames['Crown']:
      return import(`./svg/Crown.svg`);
    case EIconNames['CrownCircle']:
      return import(`./svg/CrownCircle.svg`);
    case EIconNames['CloseRound']:
      return import(`./svg/CloseRound.svg`);
    case EIconNames['Edit']:
      return import(`./svg/Edit.svg`);
    case EIconNames['Heart']:
      return import(`./svg/Heart.svg`);
    case EIconNames['Home']:
      return import(`./svg/Home.svg`);
    case EIconNames['Search']:
      return import(`./svg/Search.svg`);
    case EIconNames['ListItemDot']:
      return import(`./svg/ListItemDot.svg`);
    case EIconNames['Messages']:
      return import(`./svg/Messages.svg`);
    case EIconNames['Message']:
      return import(`./svg/Message.svg`);
    case EIconNames['NoNotifications']:
      return import(`./svg/NoNotifications.svg`);
    case EIconNames['Paywall/PremiumContent']:
      return import(`./svg/Paywall/PremiumContent.svg`);
    case EIconNames['Paywall/RomanticMode']:
      return import(`./svg/Paywall/RomanticMode.svg`);
    case EIconNames['Plus']:
      return import(`./svg/Plus.svg`);
    case EIconNames['ProfileAdd']:
      return import(`./svg/ProfileAdd.svg`);
    case EIconNames['Report']:
      return import(`./svg/Report.svg`);
    case EIconNames['ReportFilled']:
      return import(`./svg/ReportFilled.svg`);

    case EIconNames['Send']:
      return import(`./svg/Send.svg`);
    case EIconNames['Social/Apple']:
      return import(`./svg/Social/Apple.svg`);
    case EIconNames['Social/Google']:
      return import(`./svg/Social/Google.svg`);
    case EIconNames['Social/Reddit']:
      return import(`./svg/Social/Reddit.svg`);
    case EIconNames['Social/Discord']:
      return import(`./svg/Social/Discord.svg`);
    case EIconNames['Unlock']:
      return import(`./svg/Unlock.svg`);
    case EIconNames['Photo']:
      return import(`./svg/Photo.svg`);
    case EIconNames['PhotoAdd']:
      return import(`./svg/PhotoAdd.svg`);
    case EIconNames['Close']:
      return import(`./svg/Close.svg`);
    case EIconNames['Personal']:
      return import(`./svg/Personal.svg`);
    case EIconNames['CheckListItem']:
      return import(`./svg/CheckListItem.svg`);
    case EIconNames['Picture']:
      return import(`./svg/Picture.svg`);
    case EIconNames['Random']:
      return import(`./svg/Random.svg`);
    case EIconNames['Clock']:
      return import(`./svg/Clock.svg`);
    case EIconNames['Gift']:
      return import(`./svg/Gift.svg`);
    case EIconNames['VirusChecked']:
      return import(`./svg/VirusChecked.svg`);
    case EIconNames['Share']:
      return import(`./svg/Share.svg`);
    case EIconNames['CardPay']:
      return import(`./svg/CardPay.svg`);
    case EIconNames['Flash']:
      return import(`./svg/Flash.svg`);
    case EIconNames['effects/character-swiper-bg-elipse-1']:
      return import(`./svg/effects/character-swiper-bg-elipse-1.svg`);
    case EIconNames['effects/character-swiper-bg-elipse-2']:
      return import(`./svg/effects/character-swiper-bg-elipse-2.svg`);

    case EIconNames['effects/supercustom-circle-1']:
      return import(`./svg/effects/supercustom-circle-1.svg`);

    case EIconNames['effects/supercustom-circle-2']:
      return import(`./svg/effects/supercustom-circle-2.svg`);
    case EIconNames['effects/supercustom-circle-3']:
      return import(`./svg/effects/supercustom-circle-3.svg`);

    default:
      return import(`./svg/${name}`);
  }
};

export interface IIconProps extends React.HTMLAttributes<HTMLDivElement> {
  svgProps?: React.HTMLAttributes<SVGElement>;
  name: `${EIconNames}`;
}

export const Icon = (props: IIconProps) => {
  const { name, svgProps, ...attrs } = props;
  const [IconComponent, setIconComponent] =
    React.useState<React.FunctionComponent | null>(null);

  React.useEffect(() => {
    getIcon(name)
      .then((module) => {
        setIconComponent(module?.ReactComponent);
      })
      .catch(() => {
        throw new Error(
          `Icon: not fount svg file in components/Icon/svg/${name}.svg`
        );
      });
  }, []);

  return (
    <>
      {IconComponent && <IconComponent className={classNames('icon', attrs?.className, svgProps?.className)} {...attrs} {...svgProps} />}
    </>
  );
};
