import React from 'react';
import classNames from 'classnames/bind';
import { Button } from 'src/components/Button'
import styles from './WebsiteDoesNotWork.module.scss'
import { HeaderGuiest } from '../Header/HeaderGuiest';
const sx = classNames.bind(styles);

export const WebsiteDoesNotWork = () => {

  return (
    <div className={sx('container')}>
      <HeaderGuiest />
      <div className={sx('main')}>
        <div className={sx('title')}>We are upgrading website</div>
        <div className={sx('description')}>Come again in 3 hours and check new features</div>
        {/* <a href='https://photo.romanticai.com'>
          <Button variant='filled' color='primary' size='md' className={sx('button')}>Try photo generator</Button>
        </a> */}
        <div className={sx('poster')}>
          <div className={sx('poster-container')}>
            <img src="/assets/doesnotwork.webp" alt=''></img>
          </div>
        </div>
      </div>
    </div>
  )
}